import React from 'react';
import './venuepage.css';
import VenueList from '../venue/VenueList';


const VenuePage = () => {
  
 return (
  <VenueList/>
 );
};

export default VenuePage;
